import Head from 'next/head'
import Navbar from './Navbar/Navbar';
import axios from 'axios';
import {countryList, platformDefault} from './utils/server-links';
import {useEffect, useState} from 'react';
import useStore from './store';
import { useRouter } from 'next/router';
import ZendeskChat from "../components/ZendeskChat/"
import Script from 'next/script';
import ModalSessionExpiration from './Modal/ModalSessionExpiration';

type LayoutProps = {
    children: React.ReactNode
    title?: string
    fullWidth? : boolean
    fullHeight?: boolean
    isIframe?: boolean
    screenHeight?: boolean
}

const Layout = ({children, title = 'Capex Onboarding', fullWidth = false, fullHeight = false, isIframe = false, screenHeight = false}: LayoutProps) => {
    const {query} = useRouter();
    const countries = useStore(state => state.countries);
    const store = useStore(state => state)
    const user = useStore(state => state.user)

    // zendesk integration
    const zendeskLicense = user && user?.companyId;
    const zendeskLocale = user && user?.locale;
    const residencePassed = user && user?.citizenship;
    const filteredLicenses = store && store.allLicenses && residencePassed && store.allLicenses.find(license => license.companyId === zendeskLicense);
    const zendeskVisibility = filteredLicenses && {showDesktop: filteredLicenses?.zendesk_desktop, showMobile: filteredLicenses?.zendesk_mobile};

    return (
        <div
            className={`dark:bg-dark-mode ${isIframe ? `` : `min-h-screen`} ${!fullWidth ? 'md:bg-linearGradientLayout' : 'bg-white'} ${fullHeight ? 'flex flex-col w-full' : ''}`}
            // style={{backgroundImage: `${fullWidth ? "white" : "linear-gradient(252.24deg, #FFFFFF 10.73%, #F2F7F9 54.58%)"}`}}
        >
            <Head>
                <title>{title}</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                {isIframe &&
                    <style>
                        {`
                        body, html {
                            height: auto !important;
                        }
                        `}
                    </style>
                }
                
            </Head>
            {!isIframe &&
            <Navbar/>
            }
            <div
                className={`${fullWidth ? `max-w-full ` : `max-w-7xl px-5`}  dark:bg-dark-mode  mx-auto relative ${isIframe ? `` : `lg:pt-[70px] 2xl:pt-[94px]`} ${fullHeight ? 'grow flex flex-col w-full' : ''} ${screenHeight ? 'lg:h-screen' : ''}`}
            >
                {children}
                {
                    // @ts-ignore
                    user && zendeskLicense && zendeskLocale && residencePassed && <ZendeskChat license={zendeskLicense} locale={zendeskLocale} zendeskVisibility={zendeskVisibility} />
                }
            </div>
            <ModalSessionExpiration />
            {isIframe && (
                <Script strategy="lazyOnload" id="iframe-resizer" src="/js/iframe-resizer.js" />
            )}
        </div>
    )
}

export default Layout
function useQuery() {
    throw new Error('Function not implemented.');
}

