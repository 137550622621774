import logo from '../../public/capex-logo-red.svg';
import useStore from '../store';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState, Fragment, useEffect } from 'react';
import { useCustomTranslation } from '@utils/translations';
import { Menu, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Notification from '../Notification/Notification';
import useRedirectToPlatform from '../../components/hooks/usePlatformLogin';
import { useClickOutside } from '@mantine/hooks';
import useBrowserData from '@components/hooks/useBrowserData';
import ModalFullyRegistered from '@components/Modal/ModalFullyRegistered';

const languageNames = {
  en: 'English',
  ar: 'العربية',
  es: 'Español'
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = () => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const locale = router?.locale || 'en';
  const storeToken = useStore(state => state.token);
  const user = useStore(state => state.user);
  const redirectToPlatform = useRedirectToPlatform();
  const [showModalFullyRegistered, setShowModalFullyRegistered] = useState<boolean>(false);
  const [showNotification, setNotification] = useState(false);
  const browserData = useBrowserData();

  const handleLanguageChange = (languageCode: string) => {
    router.push({ pathname, query }, asPath, { locale: languageCode });
  };

  const ref = useClickOutside(() => { showNotification && setNotification(false) });

  const onClickCompleteLater = () => {
    if (pathname === "/user/identity" && (user?.companyId === 63 || user?.companyId === 65)) {
      setShowModalFullyRegistered(true);
    } else {
      setNotification(!showNotification);
    }
  };

  const onContinue = (data: boolean) => {
    setNotification(!data);
  };

  return (
    <div className="bg-white py-7 md:py-4 2xl:py-7 px-4 shadow-[0px_5px_12px_0px_rgba(0,0,0,0.04)] dark:bg-dark-mode-navbar lg:rounded-b-2xl z-[11] relative lg:fixed w-full">
      <div className="max-w-8xl flex-wrap flex mx-auto justify-between">
        <div className="relative w-[122px] md:w-[108px] 2xl:w-[122px]">
          {browserData?.appName === 'Browser' ?
            <a href={`https://capex.com`} rel="noreferrer" target="_blank" id="navbar_logo">
              <Image 
                src={logo.src}
                alt="CAPEX.com"
                width="0"
                height="0"
                sizes="100vw"
                className="w-auto h-auto block"
              />
            </a>
            :
            <Image 
              src={logo.src}
              alt="CAPEX.com"
              width="0"
              height="0"
              sizes="100vw"
              className="w-auto h-auto block"
            />
          }
        </div>
        <div className="flex justify-end items-center rightContainer lg:order-3 min-w-[122px]">
          <Menu as="div" className="relative inline-block text-left z-10">
            <div>
              <Menu.Button className="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none flex items-center">
                <Image width={27} height={18} src={locale === 'ar' ? `/images/flags/4x3/sa.svg` : locale === 'es' ? `/images/flags/4x3/lat.png` : `/images/flags/4x3/${locale}.svg`} alt="" className="ltr:mr-4 rtl:ml-4" />
                <Image width={8.5} height={7.5} src="/images/flag_down_icon.svg" alt="Graph" className="block dark:hidden" />
                <Image width={8.5} height={7.5} src="/images/flag_down_icon_dark.svg" alt="Graph" className="hidden dark:block" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute ltr:right-4 rtl:left-4 top-[60px] z-auto mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-[#2D2D2D] dark:bg-dark-mode-navbar shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden overflow-y-auto max-h-60 w-40">
                <div className="flex flex-col py-1">
                  {Object.keys(languageNames).map((langCode) => (
                    langCode !== locale &&
                    <Menu.Item key={langCode}>
                      {({ active }) => (
                        <a
                          onClick={() => handleLanguageChange(langCode)}
                          className={classNames(active ? 'bg-gray-900 text-white' : 'text-white', 'group flex items-center px-4 py-2 text-sm cursor-pointer whitespace-nowrap')}
                        >
                          <Image width={18} height={18} className="ltr:mr-2 rtl:ml-2" src={langCode === 'ar' ? `/images/flags/4x3/sa.svg` : langCode === 'es' ? `/images/flags/4x3/lat.png` : `/images/flags/4x3/${langCode}.svg`} alt={langCode} />
                          {/* @ts-ignore */}
                          <div>{languageNames[langCode]}</div>
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          {user && user.id && (!router.asPath?.includes('quiz-without-flow') && (!router?.asPath?.includes('score-calculation'))) && (
            <div ref={ref}>
              <button className="md:flex bg-transparent rounded py-[5.5px] px-[5.5px] border border-gray-400" onClick={onClickCompleteLater}>
                <XMarkIcon className='w-5 h-5' />
              </button>
              <div>
                <Notification showNotification={showNotification} onContinue={onContinue} onSaveAndExit={redirectToPlatform} />
              </div>
            </div>
          )}
        </div>
        <div className="navbarWizard w-full lg:w-auto"></div>
      </div>
      <ModalFullyRegistered
        user={user}
        show={showModalFullyRegistered}
        successHandler={redirectToPlatform}
      />
    </div>
  );
};

export default Navbar;
